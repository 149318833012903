 export const columnInfo = [
   {id: 'name', name: 'Name', hidden: false, type: 'string', size: 'large-col', description: 'Allele name (reference allele name with SNPs in VDJbase format)'},
   {id: 'imgt_name', name: 'IMGT Name', hidden: false, type: 'string', size: 'large-col', description: 'Corresponding IMGT name, if any'},
   {id: 'type', name: 'Feature Type', hidden: false, type: 'string', size: 'small-col', description: 'Type of gene (V, D, J, C) or element'},
   {id: 'novel', name: 'Novel', hidden: false, type: 'boolean', size: 'small-col', description: 'True if this allele is not in the reference set used by the pipeline'},
   {id: 'appearances', name: 'Appearances', hidden: false, type: 'integer', size: 'small-col', description: 'Number of subjects in which the allele has been inferred (click for list)'},
   {id: 'max_coverage', name: 'Max coverage', hidden: false, type: 'integer', size: 'small-col', description: 'For coding regions, the max number in any sample of exactly matching reads covering the whole region'},
   {id: 'max_coverage_sample_name', name: 'Max cov sample', hidden: false, type: 'string', size: 'small-col', description: 'The sample corresponding to the max coverage value'},
   {id: 'deleted', name: 'Deleted', hidden: false, type: 'boolean', size: 'small-col', description: 'True if is record indicates that the associated gene is deleted in the corresponding haplotypes'},
   {id: 'functional', name: 'Functional', hidden: false, type: 'string', size: 'small-col', description: 'True if the gene or element matches canonical/expected functional characteristics'},
   {id: 'notes', name: 'Notes', hidden: false, type: 'string', size: 'large-col', description: 'Notes on functionality'},
   {id: 'sequence', name: 'Sequence', hidden: false, type: 'string', size: 'small-col', description: 'Sequence of the allele (click to display)'},
   {id: 'gapped_sequence', name: 'Gapped', hidden: true, type: 'string', size: 'small-col', description: 'IMGT-gapped sequence of the allele (click to display)'},
   {id: 'gene_name', name: 'Gene Name', hidden: true, type: 'string', size: 'small-col', description: 'The name of the gene in which the allele is found'},
   {id: 'gene_type', name: 'Gene Type', hidden: true, type: 'string', size: 'small-col', description: 'Type of gene (V, D, J, C) or element'},
   {id: 'family', name: 'Gene Family', hidden: true, type: 'string', size: 'small-col', description: 'Gene family'},
   {id: 'feature_name', name: 'Feature', hidden: true, type: 'string', size: 'small-col', description: 'Name of the gene feature'},
   {id: 'feature_level', name: 'Feature Level', hidden: true, type: 'string', size: 'small-col', description: 'Feature level - eg gene or sub-feature'},
   {id: 'feature_cigar', name: 'Cigar', hidden: true, type: 'string', size: 'small-col', description: 'Cigar string for the feature alignment'},
   {id: 'feature', name: 'Feature Descriptior', hidden: true, type: 'string', size: 'small-col', description: 'eg gene, CDS or mRNA'},
   {id: 'start', name: 'Feature Start', hidden: true, type: 'integer', size: 'small-col', description: 'Start coordinate of feature'},
   {id: 'end', name: 'Feature End', hidden: true, type: 'integer', size: 'small-col', description: 'End coordinate of feature'},
   {id: 'strand', name: 'Sense', hidden: true, type: 'string', size: 'small-col', description: 'Sense of feature'},
   {id: 'dataset', name: 'Dataset', hidden: true, type: 'string', size: 'small-col', description: 'The dataset in which the allele was determined'},
];
